 #landing_page {
     background: url('../../assets/Home.png') no-repeat center center;
     background-size: cover;
 }


 .header_top {
     width: 100%;
     /* height: 700px; */
     /* background-image: linear-gradient(#061C29, #14172F); */
     /* margin-left: 10px; */
     /* border: 2px solid red; */

 }

 .header {
     height: 90px;
     transition: all 0.5s;
     z-index: 997;
     /* margin-top: 15px; */
     /* margin-left: 10px; */
 }

 .header_info {
     height: 90vh;
     bottom: 0;
     top: 0;
     width: 100%;
     /* z-index: 50; */
     flex-direction: column;
 }

 .para_header_info {
     color: #BDCDD9;
     width: 48%;
     line-height: 19px;
     font-size: 16.5px;
     font-weight: 300;
     font-family: 'Inter', sans-serif;
 }

 .header_solution {
     color: #d4dde6c0;
     font-family: 'Inter', sans-serif;
     font-size: 19px;
     font-weight: 600;
 }

 /* .info_text {
    color: #1C9CED;
} */

 .header_btn {
     padding: 8px 55px !important;
     background: linear-gradient(263.44deg, rgba(1, 133, 218, 0.5) 4.13%, rgba(151, 71, 255, 0.5) 127.66%) !important;
     border: none !important;
     gap: 10px !important;
     border-radius: 10px !important;
     font-weight: 500 !important;
 }

 .header_btn:hover {
     background: linear-gradient(263.44deg, rgba(84, 159, 209, 0.795) 4.13%, rgba(135, 77, 211, 0.5) 127.66%) !important;
 }

 .shopping_cart {
     color: #557186;
     /* background-image: linear-gradient(to bottom right, #292961, #072940); */
     background: linear-gradient(324.25deg, rgba(2, 74, 115, 0.38) 22.98%, rgba(151, 71, 255, 0.38) 111.26%);
     margin: 55px;
     padding: 15px 10px;
     border-radius: 50%;
 }

 .header.sticked {
     height: 70px;
 }

 .header .logo img {
     max-height: 40px;
     margin-right: 6px;
 }

 .header .logo h1 {
     font-size: 30px;
     font-weight: 700;
     color: gray;
     font-family: sans-serif;
 }

 .video_container {
     position: relative;
 }

 .play_icon {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     cursor: pointer;
     /* background-color: #323435; */
     background-color: rgba(26, 27, 27, 0.863);
     height: 60px;
     width: 60px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     opacity: 0;
     transition: opacity 0.3s ease;
 }

 .play_icon:hover {
     background-color: #1a1b1b;
 }

 .video_container:hover .play_icon {
     opacity: 1;
 }

 .video_timeline {
     height: 5px;
     width: 100%;
     background-color: rgba(249, 249, 249, 0.5);
     position: relative;
     transition: 250ms;
     cursor: pointer;
     border-right: 1px solid #a6a6a6;
     border-left: 1px solid #a6a6a6;
 }

 .buffer_progress {
     height: 100%;
     background-color: #d9d9d9;
 }

 .play_progress {
     height: 100%;
     background-color: #FFF;
     position: absolute;
     top: 0;
     left: 0;
     transition: 150ms;
 }

 .end_circle {
     position: absolute;
     top: 50%;
     right: -4px;
     transform: translate(50%, -50%);
     width: 10px;
     height: 10px;
     background-color: #fff;
     border-radius: 50%;
 }

 .video_length {
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 15px;
     line-height: 12px;
     font-weight: 600;
 }

 .vertical_line {
     height: 40px;
     border-left: 2px solid #ccc;
     /* margin: 0 20px; */
     /* margin-left: 10px; */
 }


 @media (max-width:767px) {
     .video_player {
         margin-top: 40px;
         width: 100% !important;
     }

     .vertical_line {
         display: none;
     }

     .shopping_cart {
         margin: 0;
     }

     .para_header_info {
         width: 100%;
     }

     .headerImg_content {
         margin-top: 10px;
         width: 300px;
     }

 }

 @media (max-width:412px) {
     .header_info {
         height: 60vh !important;
     }
 }

 @media (max-width:1024px) {
     .header_info {
         height: 70vh !important;
     }
 }

 @media (max-width:1280px) {
     .header_info {
         height: 70vh;
     }
 }