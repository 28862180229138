#smazer_product_header_top {
    /* background-image: linear-gradient(180deg, #151516, #1a1e2b); */
    background: #000;
    width: 100%;
    background: url("../../assets/sZephyrss.jpg");
    background-size: cover;

}

/* .bgx {
    border: 3px solid #14284d;
    border-top: none;
    border-left: none;
    border-bottom: none;
} */


.smazer_title,
.vidyarakshaProduct {
    /* font-family: var(--default-font);
    font-size: 130px;
    color: rgb(2, 74, 115, 90%);
    font-weight: 900; */
    font-family: var(--default-font);
    font-size: 10vw;
    /* Use viewport width units for responsiveness */
    color: rgba(2, 74, 115, 0.9);
    font-weight: 900;
    max-width: 100%;
    /* Ensure it doesn't exceed the container */
    overflow: hidden;
    /* Prevent overflow */
    white-space: nowrap;
    /* Prevent wrapping */
}

.smazer_info {
    font-family: var(--default-font);
    font-size: 40px;
    font-weight: bold;
}

.smazer_logo,
.szephyr_logo,
.smamp_max_logo {
    max-width: 100%;
    height: auto;
}

.szephyr_logo {
    z-index: 0;
}

.sm_amp_max_logo {
    background: url("../../assets/productsPage/smamp_max_header_logo.png");
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 241px;
}

.why_smazer {
    background-image: url('../../assets/productsPage/Vector\ 10.png');
    background-size: cover;
    background-position: center center;
}

.why_smazer_title {
    font-family: var(--default-font);
    font-size: 24px;
    font-weight: 500;
}

.why_smazer_info {
    font-family: var(--default-font);
    font-size: 14px;
    font-weight: var(--medium-weight);
}

.smart_name {
    font-size: 48px;
    background: linear-gradient(to bottom left, rgba(28, 156, 237, 1), rgba(151, 71, 255, 1));
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
}

.smart_sub_info {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: var(--medium-weight);
}

.first_name {
    color: #2195f2;
    font-size: 16px;
}

.easy_installation {
    font-family: var(--default-font);
    font-size: 40px;
    font-weight: bold;
    color: var(--white-color);
}

.easy_installation_info {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: var(--light-weight);
    color: var(--white-color);
}

.part_one_info {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: var(--medium-weight);
    color: var(--white-color);
}

.smazer_empty {
    position: relative;
    background: url("../../assets/productsPage/smazer_empty_box.png");
    height: 235px;
    width: 412px;
    background-size: cover;
    background-position: center center;
}

/* .phone_logo{
    position: absolute;
    right: -80px;
} */

/* #products_features_background {
    max-width: 100%;
    height: auto !important;
} */

.product_app_features_background {
    max-width: 100% !important;
    height: auto !important;
    width: 858px !important;
    max-height: 567px !important;
}

.product_app_features_background_szephyr,
.product_app_features_background_smpamp_max {
    max-width: 100% !important;
    height: auto !important;
    width: 719px !important;
    max-height: 566px !important;
}

.one_app_features_background {
    max-width: 100% !important;
    height: auto !important;
    width: 1079px !important;
    max-height: 503px !important;
}

/* .background_image_smazer {
    background-image: url('../../assets/smazer_one_app.png') !important;
    width: 1079px !important;
    height: 503px !important;
    background-size: cover;
    background-position: center center;

} */


.background_container {
    width: 100%;
    max-width: 1079px;
    /* Maximum width to keep it within layout bounds */
    overflow: hidden;
    /* Prevent content from overflowing */
    position: relative;
    /* Ensure the container positions elements correctly */
    margin: 0 auto;
    /* Center the container */
}


.background_image_smamp_max {
    background-image: url('../../assets/vidyuraksha_screen_slider_img.png') !important;
    width: 1079px;
    height: 503px;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.background_image_smazer {
    background-image: url('../../assets/smazer_one_app.png') !important;
    width: 1079px;
    height: 503px;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.background_image_szephyr {
    background-image: url('../../assets/Group\ 271c2.png') !important;
    width: 1079px;
    height: 503px;
    background-size: cover;
    background-position: center center;
    position: relative;
}

/* .background_image_szephyr {
    background-image: url('../../assets/Group\ 271c2.png') !important;
    width: 1079px !important;
    height: 503px !important;
    background-size: cover;
    background-position: center center;
}

.background_image_smamp_max {
    background-image: url('../../assets/vidyuraksha_screen_slider_img.png') !important;
    width: 1079px !important;
    height: 503px !important;
    background-size: cover;
    background-position: center center;
} */

.background-image {
    width: 1079px;
    height: 503px;
    background-size: cover;
    background-position: center center;
}


.mobile_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 45px;
}

.mobile_devices {
    height: 100%;
    width: 230px !important;
    border: 2px solid #806e7e;
    border-radius: 30px;
    background-color: #1d1d1b;
}


.mobile_top {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #000; */
}

.screenshots_images {
    background: #fff;
    height: 100%;
    /* background: url("../../assets/szephyr_screenshots/screenshot_one.jpg"); */
    background-size: cover !important;
    background-position: center center !important;
}


.benefits_title {
    font-family: var(--secondary-font);
    font-weight: var(--extra-bold-weight);
    font-size: 20px;
}

.benefits_info {
    font-family: var(--secondary-font);
    font-weight: var(--medium-weight);
    font-size: 15px;
    margin-top: 10px;
}

#benefit_section {
    border-radius: 15px;
    margin-top: 40px;
    background: url('../../assets/productsPage/benefits_logo.png');
    background-size: cover;
    background-position: center center;
}

.smazer_app_title {
    font-family: sans-serif;
    font-size: 100px;
    font-weight: 800;
    -webkit-text-stroke: 2px rgba(2, 74, 115, 1);
    color: transparent;
}

.vidyuraksha_app_title {
    font-family: sans-serif;
    font-size: 100px;
    font-weight: 800;
    -webkit-text-stroke: 2px rgba(2, 74, 115, 1);
    color: transparent;
}

.one_app_title {
    font-family: var(--default-font);
    font-weight: 200;
    font-size: 40px;
    color: var(--white-color);
}

.one_app_title_app {
    font-family: var(--default-font);
    font-weight: 200;
    font-size: 60px;
    color: var(--white-color);
}

.one_app_text {
    font-family: var(--default-font);
    font-weight: bold;
    font-size: 40px;
    background: linear-gradient(to bottom, rgba(27, 154, 234, 1), rgba(151, 71, 255, 1));
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
}

.one_app_text_app {
    font-family: var(--default-font);
    font-weight: bold;
    font-size: 60px;
    background: linear-gradient(to bottom, rgba(27, 154, 234, 1), rgba(151, 71, 255, 1));
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
}


.play_store,
.apple_ios {
    border-radius: 10px;
    border: 2px solid rgba(2, 74, 115, 1);
    color: var(--white-color);
    /* border-image: linear-gradient(rgba(2, 74, 115, 1), rgba(2, 74, 115, 0)); */
    border-image-slice: 1;
    width: 100%;
    height: 100%;
    max-width: 157px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    text-decoration: none;
}

.download_from_text {
    font-family: var(--default-font);
    font-weight: var(--light-weight);
    color: var(--white-color);
    font-size: 9px;
}

.apple_store_text,
.google_play_text {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    color: var(--white-color);
    font-size: 10px;
}

.product_color_text,
.quantity_text {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 25px;
}

.color_section_one {
    border-radius: 50%;
    background-color: white;
    width: 50px;
    height: 50px;
}

.White,
.Blue,
.Green,
.Grey {
    border-radius: 50%;
    padding: 5px;
    border: 2px dashed #fff;
}

.smazerWhite:hover,
.smazerBlue:hover,
.smazerGreen:hover,
.smazerGrey:hover,
.smampWhite:hover,
.smampBlue:hover,
.smampGreen:hover,
.smampGrey:hover,
.szephyrWhite:hover,
.szephyrBlue:hover,
.szephyrGreen:hover,
.szephyrGrey:hover {
    /* border: 2px dashed #ffd702; */
    cursor: pointer;
}

.actives {
    border: 2px dashed #ffd702;
    cursor: pointer;
}

.color_section_two {
    border-radius: 50%;
    background-color: #00008b;
    width: 50px;
    height: 50px;
}

.color_section_three {
    border-radius: 50%;
    background-color: #4b5320;
    width: 50px;
    height: 50px;
}


.color_section_four {
    border-radius: 50%;
    background-color: #808080;
    width: 50px;
    height: 50px;
}

.color_section_five {
    border-radius: 50%;
    background-color: #4b5320;
    width: 50px;
    height: 50px;
}

.quantity_btns {
    border-radius: 5px;
    border: none;
    border: 1px solid #b0b0b0;
    width: 137px;
    height: 37px;
    background-color: #D9D9D9;
}

.quantitiy_number {
    font-family: var(--default-font);
    font-size: 25px;
    font-weight: var(--semi-bold-weight);
    color: #000;
}

.quantity_button_select {
    height: 100%;
    border-radius: 5px;
    color: #3a3a3a;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
}

.quantity_button_select:active {
    transform: translateY(1px);
}

.smazer_details_info {
    background: url('../../assets/productsPage/smazer_order_bg.png');
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
}

.sampmax_details_info {
    background: url('../../assets/vidyuraksha_banner.png');
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
}

.gradient-circle {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.gradient-circle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 100%;
    background-image: radial-gradient(circle, #011b2a, rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media(max-width:767px) {
    .gradient-circle::before {
        display: none;
    }

    .why_smazer {
        background-image: none;
        border-radius: 20px;
        background-color: #111621;
    }

    .part_one_info {
        font-size: 13px;
    }
}

.szephyr_details_info {
    background: url('../../assets/productsPage/szephyr_order_bg.png');
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
}

.smamp_max_details_info {
    background: url('../../assets/productsPage/smazer_order_bg.png');
    background-size: cover;
    background-position: center center;
    border-radius: 30px;
}

.product_names {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 40px;
    color: var(--white-color);
}

.product_name_info {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 25px;
}

.product_prices {
    font-family: var(--default-font);
    font-weight: var(--regular-weight);
    font-size: 35px;
}

.price_slashed {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 26px;
    text-decoration: line-through;
}

.product_details_listing {
    border: 1px solid #fff;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.product_listing_details {
    font-family: var(--default-font);
    font-weight: var(--light-weight);
    font-size: 16px;
}

.buy_button_text {
    font-family: var(--default-font) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.order_details {
    background: url("../../assets/productsPage/order_section.png");
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
}

.product_orders_text,
.product_total {
    font-family: var(--default-font);
    font-size: 25px;
    font-weight: var(--semi-bold-weight);
    color: #737373;
}

.product_orders_price {
    font-family: var(--default-font);
    font-size: 30px;
    font-weight: var(--regular-weight);
    color: #000;
}

.total_order_price {
    font-family: var(--default-font);
    font-size: 35px;
    font-weight: var(--regular-weight);
    color: #000;
}

/* Customer_review */

.customers_review_text {
    font-family: var(--default-font);
    font-size: 36px;
    font-weight: var(--extra-bold-weight);
    text-align: center;
}

.customers_review_info {
    font-family: var(--default-font);
    font-size: 20px;
    font-weight: var(--medium-weight);
}

.customer_review {
    position: relative;
    border: 2px solid rgba(151, 71, 255, 1);
    border-radius: 15px;
    height: 323px;
    max-width: 100%;

}

.customer_review_smazer {
    position: relative;
    border: 2px solid rgba(151, 71, 255, 1);
    border-radius: 15px;
    height: 300px;
    max-width: 100%;
}

.review_logo {
    position: absolute;
    top: -20px;
    left: 30px;
    width: 57px;
    height: 55px;
    background-color: #000;
}

.customer_review_user {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: var(--semi-bold-weight);
}

.customer_review_info {
    font-family: var(--default-font);
    font-size: 14px;
    font-weight: var(--regular-weightt);
}

#faq_container {
    border-radius: 15px;
    /* background: url("../../assets/productsPage/faq_background.png"); */
    background-image: linear-gradient(to bottom left, #062030, #171432);
    background-size: cover;
    background-position: center center;
    border: 2px solid #41484c;
}

.faq_contents {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
}

.faq_title {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 16px;
}


.szephyr_logo_align {
    position: relative;
    z-index: 1;
    /* position: absolute;
    right: -190px;
    top: -70px; */
}

.szephyr_size {
    position: absolute;
    left: -75px;
    bottom: -150px;
}

.smamp_logo_align {
    position: absolute;
    right: 60px;
    top: -15%;
    /* left: 10%; */
}

.smazer_logo_align {
    position: absolute;
    right: -25%;
    top: -70%;
}

.szephyr_logo_align {
    position: absolute;
    right: -30%;
    top: -30%;
    z-index: 0;
}


.features_align {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin-top: 50px;
}

@media(max-width:767px) {
    .features_align {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-top: 20px !important;
    }
}

/* adding lines */

.part_one_number,
.part_two_number,
.part_three_number,
.part_four_number,
.easy_installation_lines {
    position: relative;
}

.part_one_number::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
}

@media (min-resolution: 100dpi) and (max-resolution: 500dpi) {

    .mobile_view_3d {
        height: 40vh !important;
    }
}

.szephyr_button_align {
    position: absolute;
    top: 60%;
}

.part_one_number::before {
    content: "";
    position: absolute;
    top: -100%;
    height: 100%;
    border-right: 3px solid #14284d;
}

.part_two_number::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
}

.part_two_number::before {
    content: "";
    position: absolute;
    top: -100%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
}

.part_three_number::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 50%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
}

.part_three_number::before {
    content: "";
    position: absolute;
    top: -100%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
}


.part_four_number::before {
    content: "";
    position: absolute;
    top: -100%;
    height: 100%;
    border-right: 2px dashed #1e9bed;
    /* border-image: linear-gradient(to bottom, #1e9bed, #14284d);
    border-image-slice: 1; */
}



/* Mobile Responsive */

/* Prodduct Smazer */

.smazer_mobile_plug {
    display: none !important;
}

.easy_install_mobile {
    display: none;
}

.mobile_view_screen {
    display: none;
}

@media (max-width: 767px) {

    .smazer_product_screen_slider,
    .szephyr_product_screen_slider,
    .smamp_product_screen_slider {
        display: none;
    }

    .mobile_view_screen {
        display: flex;
    }


    .szephyr_button_align {
        top: 90%;
        left: 40%;
    }

    .mobile_view_3d {
        height: 40vh !important;
    }

    #product_background_radial {
        height: 100%;
    }

    .smazer_title {
        font-size: 80px;
        margin-top: 30px;
    }

    .vidyarakshaProduct {
        font-size: 50px;
        margin-top: 50px;

    }

    .smazer_app_title {
        font-size: 70px;

    }

    .vidyuraksha_app_title {
        font-size: 45px;
    }

    .one_app_title_app,
    .one_app_text_app {
        font-size: 45px;
    }

    .smazer_logo_align {
        display: none
    }

    .why_smazer {
        width: 100% !important;
    }

    .smazer_mobile_plug {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .smazer_section_top {
        padding: 0 !important;
    }

    .easy_install_mobile {
        display: block;
    }

    .part_one_number_mobile,
    .part_two_number_mobile,
    .part_three_number_mobile,
    .part_four_number_mobile {
        position: relative;
    }

    .part_one_number_mobile::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 47%;
        height: 120px;
        border-right: 3px solid #14284d;
    }

    .part_one_number_mobile::before {
        content: "";
        position: absolute;
        top: -100%;
        height: 100%;
        border-right: 3px solid #14284d;
    }

    .part_two_number_mobile::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 47%;
        height: 120px;
        border-right: 3px solid #14284d;
    }

    .part_two_number_mobile::before {
        content: "";
        position: absolute;
        top: -100%;
        height: 100%;
        border-right: 3px solid #14284d;
    }

    .part_three_number_mobile::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 47%;
        height: 120px;
        border-right: 3px solid #14284d;
    }

    .part_three_number_mobile::before {
        content: "";
        position: absolute;
        top: -100%;
        height: 100%;
        border-right: 3px solid #14284d;
    }

    .part_four_number_mobile::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 47%;
        height: 50px;
        border-right: 3px solid #14284d;
    }

    .part_four_number_mobile::before {
        content: "";
        position: absolute;
        top: -100%;
        height: 100%;
        border-right: 3px solid #14284d;
    }

    .easy_install_desktop {
        display: none;
    }

    #faq_container {
        width: 100%;
    }

}

/* Product szephyr */

.szephyr_mobile_plug {
    display: none !important;
}

.mobile_view_3d {
    height: 30%;
    background: transparent;

}

@media (max-width: 767px) {
    .szephyr_logo_align {
        display: none;
    }

    .szephyr_mobile_plug {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
    }

    .szephyr_img_details {
        width: 100% !important;
    }

    .szephyr_mobile_view_3d {
        height: 40vh !important;
    }

    .szephyr_product_screen_slider {
        display: none;
    }

    .mobile_view_3d {
        height: 65% !important;
        margin-top: 40px;
    }

    /* 
    .smazer_title {
        margin-top: 30px;
    } */
}

/* Product Smamp Max */

.smampmax_mobile_plug {
    display: none !important;
}

.mobile_view_button {
    display: flex;
    justify-content: start;
    align-items: center;
}

.benefits_cont {
    padding: 16px;
}

.mobile_screenshots {
    margin-left: -20px;
}

@media (max-width: 767px) {

    .spline_3d {
        height: 30vh !important;
    }

    .review_logo {
        width: 35px;
        height: 35px;
    }

    .mobile_screenshots {
        margin-left: 0px;
    }

    .benefits_cont {
        padding: 0 !important;
    }

    .products_features_background {
        width: 90%;
    }

    .smamp_logo_align {
        display: none;
    }

    .smampmax_mobile_plug {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
    }

    .mobile_view_button {
        justify-content: center;
    }

    .smamp_product_screen_slider {
        display: none;
    }

    .samamp_max_img {
        margin-top: 20px;
    }

    .customers_review_info {
        font-size: 15px;
    }

    .mobile_view_3d {
        height: 30vh !important;
    }

    .vidyuraksha_header_img {
        margin-top: 0px !important;
    }
}

@media (max-width: 375px) {
    .background-image-mobile {
        height: 78vh !important;
    }
}

.glow-button {
    transition: border-color 0.3s ease-in-out;
}

.glow-button:hover {
    border-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 10px 0 rgba(235, 229, 229, 0.671);
    background-color: #ffffff48;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
    color: linear-gradient(to top, #9747FF, #1C9CED);
    background-color: #414141;
    border-radius: 50%;
    margin-left: -10px;
    margin-right: -10px;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
    font-size: 22px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .tablet_view {
        height: 80% !important;
        width: 100% !important;
        /* padding: 5px !important; */
        /* overflow: hidden; */
    }
}

@media screen and (max-width: 320px) {}