.iot_domain_top {
    width: 100%;
    height: 800px;
    background: linear-gradient(rgba(0, 0, 0, 0.74),
            rgba(0, 0, 0, 0.589)), url("../../../assets/homePage/iot_pic.png") center/cover no-repeat;
}

.iot_heading_info span {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 128px;
    text-shadow: 0 0 10px rgb(28, 156, 237, 0.5);
}

.iot_content_text {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 16px;
    color: #a9a7a6;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.iot_main_container {
    height: 80%;
}

.in_house_heading_info {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 64px;
    color: #fff;
}

.in_house_text {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 16.5px;
    color: #C8C0C0;
}

@media(max-width:767px) {
    .iot_main_container {
        height: 70%;
    }
    .in_house_heading_info{
        font-size: 30px;
    }
}