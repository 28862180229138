.box {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    background: rgba(37, 37, 37, 1);
    height: 25vh;
    background: linear-gradient(235.89deg, rgba(28, 156, 237, 0.2) 5.08%, rgba(151, 71, 255, 0.2) 104.46%);

}

.services_text_home {
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 19px;
    color: #fefefe;
}

.service_heading{
    color: #fefefe;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 36px;
}

.serviceProvide_title {
    font-family: "Inter";
    font-size: 36px;
    color: #fff;
    font-weight: 800;
}

.custom-card {
    border-radius: 10px !important;
    height: 284px !important;
    width: 541px !important;
    min-height: auto !important;
    max-width: 100% !important;
    border: 2px solid rgb(64, 64, 64) !important;
}

.custom-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.serviceProvide_subhead {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 800;
    text-align: center;
}

.serviceProvie_content {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 100;
    text-align: center;
    /* width: 90%; */
}