/* Domain Section */
.domain_text {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #fff;
}

.alternate_sub_color {
    color: #1074B4;
}

.custom-card-domain {
    border-radius: 10px !important;
    height: 206px !important;
    width: 360px !important;
    min-height: auto !important;
    max-width: 100% !important;
    /* height: 284px !important;
    width: 541px !important;
    min-height: auto !important;
    max-width: 100% !important; */
    border: 2px solid rgb(64, 64, 64) !important;
}

.custom-card-domain:hover,
.custom-card-domain:active {
    box-shadow: 0 4px 8px rgba(199, 187, 187, 0.671) !important;
    /* Slight shadow effect */
}

.custom-card-body-domain {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.domain_subhead {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    color: var(--white-color) !important;
}

#domain_section {
    padding: 80px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.rail_way {
    padding: 40px 0;
    /* border: 2px solid red; */

}

.railway_text {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 40px;
    color: #FEFEFE;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

.railway_para {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #FEFEFE;
    line-height: auto;
    letter-spacing: 0.05em;
}

.iot_text {
    font-family: "Nunito", sans-serif;
    color: #FEFEFE;
    font-size: 40px;
    font-weight: 700;
    line-height: 54px;
}

.iot_para {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0.05em;
    color: #FEFEFE;
}

.cloud_text,
.space_text {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 40px;
    color: #FEFEFE;
    line-height: 54px;
}

.cloud_para {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #FEFEFE;
    line-height: auto;
    letter-spacing: 0.05em;
}


.domain_images_iot {
    max-width: 100%;
    width: 487px;
    height: auto;
    max-height: 273px;
}

.domain_images_cloud {
    max-width: 100%;
    width: 370px;
    height: auto;
    max-height: 344px;
}

.domain_images_railway {
    max-width: 100%;
    width: 420px;
    height: auto;
    max-height: 260px;
}

.space_para {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #FEFEFE;
    line-height: auto;
}

#space {
    height: 420px;
    padding: 50px;
    background:
        /* linear-gradient(rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.705)), */
        url("../../assets/homePage/space_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.satellite {
    position: absolute;
    top: -5px;
    right: 65px;
}

.satellite_img {
    max-height: 350px;
}

.satellite-container {
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
}


.cloud_effect,
.railway_effect,
.iot_effect,
.space_effect {
    padding: 30px 10px;
    transition: 0.5s;
    transform: scale(1.1);
}

.cloud_effect:hover,
.railway_effect:hover,
.iot_effect:hover,
.space_effect:hover {
    transform: scale(1);
    cursor: pointer;
}

@media(max-width:767px) {

    /* .satellite {
        right: -80px;
        top: 200px;
    } */
    #domain_section {
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;

    }
}

.cloud_logo {
    background: transparent;
}

.domain_text_alt {
    color: #1C9CED;
}

.mobile_iot {
    display: none;
}

.mobile_railway {
    display: none;
}

.mobile_cloud {
    display: none;
}


@media(max-width:768px) {
    .desktop_iot {
        display: none;
    }

    .mobile_iot {
        display: flex;
    }

    .desktop_railway {
        display: none;
    }

    .mobile_railway {
        display: flex;
    }

    .desktop_cloud {
        display: none;
    }

    .mobile_cloud {
        display: flex;
    }

    #domain_section {
        padding: 80px 30px;
    }
}