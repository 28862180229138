.space_domain_top {
    width: 100%;
    height: 700px;
    /* background: url("../../../assets/newspace.png") center/cover no-repeat; */
    background:
        /* linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), */
        url("../../../assets/domain_space_bg1.jpg") center/cover no-repeat;
}

.space_background {
    /* background: url("../../../assets/homePage/Domains/Rectangle\ 5692.png");
    background-size: cover;
    background-position: center center;*/
    width: 100%;
    background-image: linear-gradient(to right, #090909, #1a1a1a);
    border: 2px solid #333333;
    border-radius: 10px;
}

.our_projects {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    color: var(--white-color);
    font-size: 20px;
}

.space_satelite {
    position: relative;
    height: 200px;
}

.space_satelite_main {
    position: absolute;
    top: -200px;
}

.space_satelite_main>img {
    width: 100%;
    height: 403px;
}

@media screen and (max-width: 767px) {
    .space_satelite_main {
        position: static;
        top: auto;
    }

    .space_satelite_main>img {
        width: auto;
        height: auto;
    }
}

@media (max-width:375px) {
    .railway_heading_info {
        margin-top: 40px;
    }

}