.railway_domain_top {
    width: 100%;
    height: 700px;
    background: 
    linear-gradient(rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.589)),
     url("../../../assets/railwayBackground.png") center/cover no-repeat;
}

.railway_heading_info {
    font-family: "Inter", sans-serif;
    font-weight: var(--semi-bold-weight);
    font-size: 128px;

}


.railway_button {
    padding: 8px 40px !important;
    background: linear-gradient(263.44deg, rgba(1, 133, 218, 0.5) 4.13%, rgba(151, 71, 255, 0.5) 127.66%) !important;
    border: none !important;
    gap: 10px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
}

.railway_button:hover {
    background: linear-gradient(263.44deg, rgba(84, 159, 209, 0.795) 4.13%, rgba(135, 77, 211, 0.5) 127.66%) !important;
}

.railway_contents {
    padding-bottom: 25px;
    border: 2px solid #323232;
    border-radius: 10px;
    height: 60%;
    background-image: linear-gradient(to bottom right, rgb(255, 255, 255, 0%), rgba(255, 255, 255, 0.192));
}

.railway_background {
    background-color: #191919;
    border: 2px solid #353535;
}

.our_projects {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    color: var(--white-color);
    font-size: 20px;
}

@media(max-width:767px) {
    .railway_heading_info {
        font-size: 50px;
    }

    .railway_contents {
        height: 30vh;
        padding-top: 30px;
    }
}