.defence_domain_top {
 width: 100%;
 height: 700px;
 background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.356)),
  url("../../../assets/defence-image.jpg") center/cover no-repeat;
}

#defence_background {
 background: #000;
 width: 100%;
 background: url("../../../assets/sZephyrss.jpg");
 background-size: cover;
}

.flight-deck {
 font-family: var(--default-font);
 font-weight: var(--extra-bold-weight);
 font-size: 24px;
 color: #007be5;
}

.flight-deck-content {
 font-family: var(--default-font);
 font-weight: var(--regular-weight);
 font-size: 16px;
 color: #fff;
}

.flight-deck-content-two {
 font-family: var(--default-font);
 font-weight: var(--medium-weight);
 font-size: 16px;
 color: #fff;
 background-color: #292929;
 border-radius: 10px;
 padding: 3px;
}

@media (max-width: 767px) {
 .flight-deck-content {
  width: 100% !important;
 }

 .flight-deck-content-two {
  width: 100%;
 }
}
