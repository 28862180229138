#aboutUs_header_top {
    background-color: #000;
    width: 100%;
    /* height: 700px; */
}

#our_story {
    /* height: 700px; */
    background: linear-gradient(rgb(9, 40, 58), rgba(49, 6, 105, 0.959));
}

.get_apps_title {
    font-family: var(--default-font);
    color: var(--white-color);
    font-weight: 800;
    font-size: 36px;

}

.aboutus_head {
    font-family: "Inter";
    font-size: 48px;
    font-weight: 800;
    color: #fff;
}

.our_location_contents {
    font-family: var(--default-font);
    color: var(--white-color);
    font-size: 13px;
    font-weight: 400;
}

.our_story_box {
    height: 20vh;
    background: url("../../assets/aboutUs/ourStoryRight.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.our_story_boxes {
    height: 20vh;
    background: url("../../assets/aboutUs/ourStoryLeft.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.rounded_story_button,
.rounded_story_button_end {
    height: 30%;
    border-radius: 50%;
    background: #1C9CED;
}

.story_year_text {
    font-family: var(--default-font);
    font-size: 36px;
    font-weight: var(--light-weight);
    color: #d8d6db;
}

.rounded_story_button {
    position: relative;
}

.rounded_story_button::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 47%;
    height: 120px;
    border-right: 2px solid var(--white-color);
}

.shopping_cart_aboutUs {
    color: #4e616d;
    background-image: linear-gradient(to bottom right, rgb(2, 74, 115, 15%), rgb(2, 74, 115, 38%));
    margin: 55px;
    padding: 15px 10px;
    border-radius: 50%;
}

.fade-in {
    animation: fadeInAnimation 1s ease-in-out forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


#aboutus_section_two {
    background-color: #04141d;
}

.section_two_details {
    border-right: 1px solid #737373;
    font-family: var(--default-font);
    font-size: 36px;
    font-weight: bold;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px !important;
}

@media(max-width:767px) {
    .section_two_details {
        border-bottom: 1px solid #737373;
        border-right: none;
        align-items: center;
        padding-left: 0 !important;
        padding-bottom: 10px !important;
    }
}

.details_title {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: bold;
    color: var(--white-color);
}

/* proud partners */
.proud_partners_title {
    font-family: var(--default-font);
    font-weight: var(--extra-bold-weight);
    color: var(--white-color);
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.core_value_info {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    color: var(--white-color);
    font-size: 20px;
}

.branch_office {
    font-family: "Inter";
    font-size: 15px;
    font-weight: bold;
    width: 25vh;
}

/* Our team */

.team_box {
    border-radius: 10px;
    height: 179px !important;
    width: 473px !important;
    background: #141414;
}

@media(max-width:767px) {
    .team_box {
        width: 100% !important;
        height: 100% !important;
    }
}

.team_content_title {
    font-weight: var(--extra-bold-weight);
    font-family: var(--default-font);
    font-size: 24px;
}

.team_content_subtitle {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 14px;
    color: #1c9ced;
}

.team_content_info {
    font-family: var(--default-font);
    font-size: 12px;
    font-weight: var(--medium-weight);
    color: #616364;
}

.custom-box {
    /* border: 1px solid #ccc; */
    /* padding: 20px; */
    margin: 20px;
}

/* Add additional styling for the image and content if needed */
.custom-box img {
    max-width: 100%;
    height: auto;
}

/* Careers */

.careers_box {
    height: 300px;
    /* width: 365px; */
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 25px;
    background-image: linear-gradient(to bottom left, rgba(28, 156, 237, 60%), rgba(151, 71, 255, 60%));
}

.careers_title {
    font-family: var(--default-font);
    font-size: 20px;
    font-weight: 500;
    color: var(--white-color);
}

.careers_contents {
    font-family: var(--default-font);
    font-size: 14px;
    font-weight: 500;
    color: var(--white-color);
}

.careers_button {
    font-family: var(--default-font);
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px;
    padding: 5px 40px;
    background-color: var(--white-color);
    color: #9747FF;
    border: none;
}

.careers_box_contents {
    height: 100%;
}

.career_apply_button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.view_all_aboutUs_career {
    position: absolute;
    top: 50px;
    right: -80px;
    /* background-image: linear-gradient(to bottom right, rgb(2, 74, 115, 15%), rgb(2, 74, 115, 38%)); */
    background: #D9D9D9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.801);
    margin: 55px;
    padding: 15px;
    border-radius: 50%;
}

.view_all_link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: var(--default-font);
    font-size: 20px;
    font-weight: var(--medium-weight);
    color: #0086d2;
    text-decoration: underline;
}

.arrow_left,
.arrow_right {
    cursor: pointer;
    transform: scale(1);
}

.arrow_left:active,
.arrow_right:active {
    transform: scale(2);
}

.arrow_left.disabled,
.arrow_right.disabled {
    cursor: default;
    pointer-events: none;
}

@media(max-width:767px) {
    .career_apply_button {
        justify-content: flex-start;
    }

    .view_all_aboutUs_career {
        display: none;
    }
}


/* Life at iinvsys */
#life_at_iinvsys {
    background-color: #000;
}

.section-header {
    text-align: center;
    font-size: var(--default-font);
    font-weight: bold;
    font-size: 36px;
    /* padding: 30px 0; */
    color: var(--white-color);
}

.section-subheader {
    text-align: center;
    font-size: var(--default-font);
    font-weight: bold;
    font-size: 26px;
    color: var(--white-color);
}

.custom-image {
    height: 254px;
    width: 100%;
    object-fit: cover;
}

.slider_image {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 10%;
}

@media(max-width:767px) {
    .slider_image {
        width: 40%;
    }
}

.rounded_button {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #737373;
    border: 2px solid #4E4C4C;
}

/* .image_slider {
    transition: 0.3s;
    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
} */

.image_slider img {
    padding: 30px 10px;
    transition: 0.5s;
    transform: scale(1.1);
}

.image_slider:hover img {
    transform: scale(1);
}

.responsive-div {
    height: 254px;
    width: 350px;
    max-width: 100%;
    box-sizing: border-box;
}

.slider_container {
    position: relative;
}

.arrows {
    position: absolute;
    border-radius: 50%;
    left: 90%;
    background-color: #3e4143;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}

.arrow-left {
    position: absolute;
    border-radius: 50%;
    right: 90%;
    background-color: #3e4143;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}

.arrows:hover,
.arrow-left:hover {
    background-color: #515557;
}

.slider_transition {
    border: 2px solid red;
}

@media (max-width: 767px) {
    .responsive-div {
        width: 100%;
    }
}

.img-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
}

.button-contianer {
    display: flex;
    justify-content: space-between;
}

.mySwiper .swiper-pagination-bullet {
    background: #737373 !important;
    width: 10px !important;
    /* Adjust the width */
    height: 10px !important;
    /* Adjust the height */
    /* Bullet color */
}

#our_location_aboutus {
    background-color: #000;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}


#our_location_main {
    /* width: 1022px; */
    height: 441px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom left, rgba(28, 156, 237, 20%), rgba(164, 95, 255, 20%));
}

.our_location_subhead {
    font-family: var(--default-font);
    color: var(--white-color);
    font-size: 16px;
    font-weight: 600;
}

.head_q1_aboutus {
    height: 263px;
    width: 489px;
    border-radius: 10px;
    background-color: rgb(217, 217, 217, 9%);
    margin: 5px;

}

.head_q1_main {
    /* height: 25vh; */
    border-radius: 10px;
    background-color: rgb(217, 217, 217, 9%);
    margin: 5px;
}

@media(max-width:767px) {
    .head_q1_aboutus {
        height: 100%;
        width: 100%;
    }

    #our_location_main {
        padding: 20px;
        width: 90%;
        height: 100%;
    }

    #our_location {
        height: 600px;
        width: 95%;
    }
    .branch_office{
        width: 50%;
    }
}