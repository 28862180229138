:root {
    --white-color: #fff;
    --default-font: "Inter", sans-serif;
    --secondary-font: "Nunito", sans-serif;
    --extra-bold-weight: 800;
    --medium-weight: 500;
    --semi-bold-weight: 600;
    --light-weight: 300;
    --regular-weight: 400;
}



body {
    margin: 0;
    padding: 0;
    /* background-color: rgba(0, 0, 0, 0.925) !important; */
    box-sizing: border-box;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

.company_logo {
    display: flex;
    justify-content: center;
    min-height: 50px;
    width: 140px;
}

.heading_info {
    font-family: var(--default-font) !important;
}

/* Header Navigation */

@media (min-width: 767px) {
    .navbar {
        padding: 0;
    }

    .navbar ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navbar li {
        position: relative;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 30px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        color: #B4BABF;
        white-space: nowrap;
        transition: 0.3s;
        text-decoration: none;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: #055783c7 !important;
    }

    .navbar .dropdown ul {
        display: block;
        position: absolute;
        left: 14px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: white;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
        border-radius: 4px;
    }

    .navbar .dropdown ul li {
        min-width: 200px;
    }

    .navbar .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.863);
    }

    .navbar .dropdown ul a i {
        font-size: 12px;

    }

    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
        color: black;
    }

    .navbar .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navbar .dropdown .dropdown ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
    }

    .navbar .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
    }
}

/* @media (min-width: 1280px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
} */

.mobile-nav-toggle {
    display: none;
}


@media (max-width: 767px) {
    .navbar {
        padding: 0;
        z-index: 9997;
    }

    .navbar ul {
        display: none;
        position: absolute;
        inset: 55px 15px 15px 15px;
        padding: 10px 0;
        margin: 0;
        border-radius: 10px;
        background-color: white;
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-family: sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: grey;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: grey;
    }

    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
        position: static;
        display: none;
        z-index: 99;
        padding: 10px 0;
        margin: 10px 20px;
        background: white;
        transition: all 0.5s ease-in-out;
        box-shadow: 0px 0px 30px rgba(black, 0.1);
    }

    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
        display: block;
    }

    .mobile-nav-toggle {
        display: inline-block;
        color: grey;
        font-size: 28px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
    }

    /* .mobile-nav-toggle.bi-x {
        color: black;
    } */

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        position: fixed;
        top: 40px;
        right: 25px;
        z-index: 9999;
        /* color: white; */
    }

    .mobile-nav-active .navbar {
        position: absolute;
        overflow: hidden;
        inset: 0;
        background: rgba(black, 0.6);
        transition: 0.3s;
    }

    .mobile-nav-active .navbar>ul {
        display: block;
        margin-top: 30px;
        /* position: absolute;
      top: 80px; */
        /* background-image: linear-gradient(#135379, #222a69); */
        background-image: linear-gradient(#082f46, #222a69);

    }

    .mobile-nav-active .navbar ul li a {
        color: #c6cdd3;
        text-decoration: none;
        font-size: 23px;
    }

    .mobile-nav-active .navbar ul li a:hover {
        color: #055783c7 !important;
    }
}


/* get_in_touchform */

#get_in_touchform {
    max-width: 65% !important;
    height: 100vh;
}

.get_touch_label {
    font-family: var(--default-font);
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
}

.subject_radio_label {
    font-family: var(--default-font);
    font-weight: bold;
    font-size: 14px;
    color: var(--white-color);
}

.getin_touch_input {
    background: transparent !important;
    color: var(--white-color) !important;
    border: 2px solid #C4C4C4 !important;
}

.custom-select {
    background: transparent !important;
    color: var(--white-color) !important;
    border: 2px solid #C4C4C4 !important;
}

.send_message_button {
    font-family: var(--default-font);
    font-weight: 500;
    color: var(--white-color);
    font-size: 14px;
    background: linear-gradient(to left, rgba(1, 133, 218, 50%), rgba(151, 71, 255, 50%));
    padding: 5px 30px;
    border-radius: 10px;
    border: none;
}

.search_button,
.send-button,
.buy_now_button {
    background: linear-gradient(to bottom left, rgb(1, 133, 218, 50%), rgb(151, 71, 255, 50%)) !important;
    border: none !important;
    width: 200px;
}

.search_button:active,
.send-button:active,
.buy_now_button:active {
    background-color: #e6e1e1 !important;
    text-decoration: none !important;
    color: #222 !important;
}


@media(max-width:767px) {
    #get_in_touchform {
        max-width: 100% !important;
    }

    .message_input {
        margin-top: 3rem !important;
    }
}

.accordion-button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.accordion-button:active {
    background-color: transparent !important;
    box-shadow: none !important;
}



.swiper-pagination-bullet {
    background-color: #f3eded !important;
    width: 15px !important;
    height: 15px !important;
}

.swiper-pagination-bullet-active {
    background-color: blue !important;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.arrow-icon {
    position: absolute;
    top: 55%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 99;
}

.arrow-icon2 {
    position: absolute;
    top: 70%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 99;
}

.form-select.prod_reg {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px;
    background-color: transparent;
    color: white;
    border: none;
    width: 100%;
    z-index: 1;
}

/* .form-select.prod_reg option {
    background-color: black;
    color: white;
  } */

.dropdown_box_smazer {
    background: gray;
    border-radius: 10px;
    height: 15vh;
}

.dropdown_box_szephyr {
    background: gray;
    border-radius: 10px;
    height: 15vh;
}

.dropdown_box_sampmax {
    background: gray;
    padding: 5px;
    border-radius: 10px;
    height: 15vh;
}

.dropdown_products {
    /* position: absolute;
    left: 0px;
    top: 14%;
    z-index: 99; */
}


.dropdown_product_links {
    cursor: pointer;
    text-decoration: none;
    padding: 1rem;
}

.dropdown_product_links:hover {
    background-color: #02436842;
    border-radius: 10px;
}

.link_dropdown_text {
    color: #0F537A;
    font-size: 24px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;

}

.link_dropdown_content {
    color: #818181;
    font-size: 15px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {

    .link_dropdown_text,
    .link_dropdown_content {
        font-size: 15px;
    }
    .dropdown_product_links{
        padding: 0.5rem;
    }
}

    /* 
.three-js-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */

    /* Header new component */
    .header_navigations {
        font-family: var(--default-font) !important;
        font-weight: var(--regular-weight) !important;
        font-size: 16px !important;
        color: #80868a !important;
        text-decoration: none;

    }

    .header_navigations:hover,
    .header_navigations.active,
    .header_navigations.active:focus {
        color: #055783c7 !important;
    }

    /* Page Not Found */

    .pageNotFoundContents {
        font-family: var(--default-font);
        font-weight: var(--medium-weight);
        color: #fff;
    }

    /* Product Smazer and szephyr */

    .prod-smazer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-right: 0px !important;
        /* gap: 20px; */
    }

    .box-product {
        width: calc(50% - 20px);
        background-color: transparent;
        /* padding: 20px; */
        box-sizing: border-box;
    }

    .heading_info {
        margin-top: 24px;
        margin-left: 4px;
    }

    @media screen and (max-width: 768px) {
        .dropdown_products {
            display: none;
        }

        .box-product {
            width: 100%;
        }

        .heading_info {
            margin-top: 0px;
            margin-left: 0px;
        }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        .dropdown_products {
            display: none;
        }

        .video_player {
            width: 100% !important;
        }

        .navbar {
            padding: 0;
            z-index: 9997;
        }

        .navbar ul {
            display: none;
            position: absolute;
            inset: 55px 15px 15px 15px;
            padding: 10px 0;
            margin: 0;
            border-radius: 10px;
            background-color: white;
            overflow-y: auto;
            transition: 0.3s;
            z-index: 9998;
        }

        .navbar a,
        .navbar a:focus {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            font-family: sans-serif;
            font-size: 15px;
            font-weight: 600;
            color: grey;
            white-space: nowrap;
            transition: 0.3s;
        }

        .navbar a i,
        .navbar a:focus i {
            font-size: 12px;
            line-height: 0;
            margin-left: 5px;
        }

        .navbar a:hover,
        .navbar .active,
        .navbar .active:focus,
        .navbar li:hover>a {
            color: grey;
        }

        .navbar .dropdown ul,
        .navbar .dropdown .dropdown ul {
            position: static;
            display: none;
            z-index: 99;
            padding: 10px 0;
            margin: 10px 20px;
            background: white;
            transition: all 0.5s ease-in-out;
            box-shadow: 0px 0px 30px rgba(black, 0.1);
        }

        .navbar .dropdown>.dropdown-active,
        .navbar .dropdown .dropdown>.dropdown-active {
            display: block;
        }

        .mobile-nav-toggle {
            display: inline-block;
            color: grey;
            font-size: 28px;
            cursor: pointer;
            line-height: 0;
            transition: 0.5s;
        }

        /* .mobile-nav-toggle.bi-x {
        color: black;
    } */

        .mobile-nav-active {
            overflow: hidden;
        }

        .mobile-nav-active .mobile-nav-toggle {
            position: fixed;
            top: 40px;
            right: 25px;
            z-index: 9999;
            /* color: white; */
        }

        .mobile-nav-active .navbar {
            position: absolute;
            overflow: hidden;
            inset: 0;
            background: rgba(black, 0.6);
            transition: 0.3s;
        }

        .mobile-nav-active .navbar>ul {
            display: block;
            margin-top: 30px;
            /* position: absolute;
      top: 80px; */
            /* background-image: linear-gradient(#135379, #222a69); */
            background-image: linear-gradient(#082f46, #222a69);

        }

        .mobile-nav-active .navbar ul li a {
            color: #c6cdd3;
            text-decoration: none;
            font-size: 23px;
        }

        .mobile-nav-active .navbar ul li a:hover {
            color: #055783c7 !important;
        }

        .tab_nav_responsive {
            height: 50%;
        }

        /* .vertical_line{
        display: flex !important;
    } */
        .headerImg_content {
            width: 650px !important;
            gap: 10px;
        }

        .heading_info {
            margin-top: 24px;
        }

        .box-product {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: none;
        }

        .why_smazer {
            width: 40vh !important;
        }

        .iot_main_container {
            height: 70%;
        }
    }