/* Vision */

.user_review {
    /* padding: 100px 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 100px; */
    /* padding-left: 30px;
    padding-right: 30px; */

}


.border_user {
    border: 2px solid #11598E;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-right: 2px solid #11598E;
    border-left: 2px solid #11598E;
    padding: 20px;
    padding-bottom: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-right: 20px;
}

.device_used {
    font-size: 15px;
    font-weight: 700;
    background: linear-gradient(95.7deg, #1C9CED 45.46%, #9747FF 108.62%);
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
    font-family: 'Nunito', sans-serif;
}

.device_numbers {
    font-family: 'Nunito', sans-serif;
    font-size: 40px;
    font-weight: 400;
    color: #9F9EA8;
    margin-left: 10px;
}

.visions_info {
    padding: 0 0 35px 0;
    flex-direction: column;
}

.vision_text {
    color: #FEFEFE;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: 700;
}

.visions_para {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 300;
    color: #fff;
}

.vision_text_alter {
    width: 55%;
}

.vision_text_alt {
    color: #1C9CED;
}

@media (max-width: 768px) {

    .vision_text_alter {
        width: 100%;
    }

    .device_numbers {
        font-size: 25px;
    }
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .border_user {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        height: 45px;
    }
}