#container_support {
    display: flex;
    flex-wrap: wrap;
    background: #0F0F0F;
    padding: 80px 0;
}

.box_support {
    flex: 1;
    min-width: 0;
    /* Allow content to shrink within flex container */
    padding: 20px;
    box-sizing: border-box;
    margin: 100px 0;
}

.full-width-section {
    width: 100%;
    height: 350px;
    /* background-color: #f0f0f0; */
    /* padding-top: 20px;
    padding-right: 25px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #fefefe;
    text-align: center;
    margin-bottom: 20px;
}

@media(max-width:768px) {
    .full-width-section {
        height: 100%;
        border-bottom: 1px solid #fefefe;
        border-right: none;
        padding-bottom: 30px;
    }
}

.icon {
    font-size: 2em;
    margin-bottom: 10px;
    color: #fefefe;

}

.heading {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fefefe;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
}

.paragraph {
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #fefefe;
}

.button {
    display: inline-block;
    padding: 8px 40px;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fefefe;
    background-color: transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 600px) {
    .box {
        flex-basis: 100%;
    }
}