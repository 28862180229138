.cloud_domain_top {
    width: 100%;
    background: url("../../../assets/Cloud2.png");
    background-size: cover;
    background-position: center center;
    /* background-image: linear-gradient(rgb(0, 0, 0, 100%), rgb(20, 80, 170, 100%), rgb(24, 96, 204, 100%)); */
    /* height: 700px; */
}

.cloud_heading_info {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 128px;
    background: linear-gradient(rgb(255, 255, 255, 100%), rgb(255, 255, 255, 0%));
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
}


.computing_text {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 36px;
    background: linear-gradient(rgb(255, 255, 255, 100%), rgb(54, 154, 245, 100%));
    letter-spacing: 3px;
    -webkit-background-clip: text;
    background-clip: none;
    color: transparent;
}

.cloud_button {
    padding: 8px 40px !important;
    background: linear-gradient(to bottom left, rgb(38, 140, 204, 100%), rgb(100, 1, 228, 100%)) !important;
    border: none !important;
    gap: 10px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
}

.cloud_button:hover {
    background: linear-gradient(263.44deg, rgba(84, 159, 209, 0.795) 4.13%, rgba(135, 77, 211, 0.5) 127.66%) !important;
}

.project_box {
    border-radius: 10px;
    border: 2px solid #497fd2;
    background-color: rgb(255, 255, 255, 10%);
}

.our_projects {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    color: var(--white-color);
    font-size: 20px;
}

.spline-img {
    margin-left: 100px;
}

.project_cloud {
    padding: 50px;
}

@media(max-width:767px) {
    .project_cloud {
        padding: 15px;
    }

    .spline-img {
        margin-left: 0;
    }
}