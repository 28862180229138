::placeholder {
    color: #fff !important;
    font-size: 16px;
    /* Change to your desired color */
}

#contactus_header_top {
    background-color: #000;
    width: 100%;
    height: 780px;
}

.contactus_title {
    font-family: var(--default-font);
    font-weight: var(--semi-bold-weight);
    font-size: 32px;
}

.prod_reg {
    font-family: var(--default-font);
    font-size: 16px;
    font-weight: var(--medium-weight);
}

.contactus_label {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 20px;
}

.contactus_field {
    background: transparent !important;
    border: 2px solid #C4C4C4 !important;
    border-radius: 0px !important;
    width: 100% !important;
}

.product_type_field {
    background: black;
    color: #fff;
    border-radius: 0px !important;
    border: 2px solid #C4C4C4 !important;
}



.prod_reg_input {
    background: transparent !important;
    color: var(--white-color) !important;
    border: 2px solid #C4C4C4 !important;
    border-radius: 0px !important;
}

/* Product Registration Confirm */

#product_confirm_reg_header_top {
    background-color: #000;
    width: 100%;
}

.product_name {
    font-family: var(--default-font);
    font-weight: var(--medium-weight);
    font-size: 14px;
    color: var(--white-color);
}

.product_info {
    font-family: var(--default-font);
    font-weight: var(--light-weight);
    font-size: 20px;
    color: var(--white-color);
}

.reg_prod {
    font-family: var(--default-font);
    font-weight: 400;
    font-size: 20px;
    text-decoration: none;
}

.prod_reg_text {
    font-family: var(--default-font);
    color: #b6b6b6;
    font-weight: 400;
    font-size: 20px;
}

/* Product Reg Form */
#product_reg_form_header_top {
    background-color: #000;
    width: 100%;
}



.product_infos {
    font-family: var(--default-font);
    font-size: 24px;
    font-family: var(--medium-weight);
    color: var(--white-color);
}

.prod_reg_input {
    background: transparent !important;
    color: var(--white-color) !important;
    border: 2px solid #C4C4C4 !important;
    border-radius: 0px !important;
}

@media(max-width:767px) {
    .main_cont {
        width: 100%;
    }

    .sub_cont {
        width: 100%;
    }
}