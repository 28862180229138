#footer {
 background: #181818;
 color: #fefefe;
 height: 467px;
 padding: 48px;
}

.footer_sub {
 font-family: "Inter";
 font-weight: 400;
 font-size: 13px;
 line-height: 15px;
 color: #fff;
 cursor: pointer;
}

.footer_details {
 height: 40vh;
}

.footer_details_company {
 height: 33vh;
}

@media (max-width: 768px) {
 .footer_details {
  height: 100%;
 }

 .footer_details_company {
  height: 100% !important;
 }
}

.footer_sub_head {
 font-family: "Inter";
 font-weight: 700;
 font-size: 16px;
 line-height: 19px;
 color: #fff;
}

.footer_logo {
 height: 50px;
 cursor: pointer;
}

.more_options {
 font-family: var(--default-font);
 font-weight: var(--light-weight);
 color: #404040;
 font-size: 13px;
 text-decoration: underline;
 cursor: pointer;
}

.footer_contents,
.location_header {
 cursor: pointer;
}

.image-container {
 position: relative;
 display: inline-block;
}

.hover-text {
 display: none;
 position: absolute;
 font-family: "Inter";
 font-weight: 700;
 font-size: 12px;
 line-height: 19px;
 color: #fff;
 /* bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px; */
}

.image-container:hover .hover-text {
 display: block;
}

@media (max-width: 767px) {
 #footer {
  height: 100%;
  padding: 0 !important;
 }

 .box_footer {
  flex-direction: column;
 }

 .box_footer_two {
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  padding: 0 !important;
 }

 .box_footer_one {
  margin-top: 20px;
  flex-direction: row;
  width: 100%;
 }
}

@media screen and (width: 1024px) and (height: 1366px) {
 #footer {
  height: 700px;
  padding: 0;
 }
}

@media screen and (width: 820px) and (height: 1180px) {
 #footer {
  height: 500px;
  padding: 10px;
 }
}

@media screen and (width: 768px) and (height: 1024px) {
 #footer {
  height: 100%;
  padding: 10px;
 }
}

@media screen and (width: 912px) and (height: 1368px) {
 #footer {
  height: 700px !important;
  padding: 0;
 }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
 #footer {
  padding: 10px;
 }
}

.office-location-img {
 max-width: 100%;
 height: auto;
}

@media (max-width: 767px) {
 .office-location-img {
  width: 80%;
  height: auto;
 }
}

.footer .box-footer {
 background-color: rgba(255, 255, 255, 0.1);
 padding: 20px;
 text-align: center;
 border: 1px solid rgba(255, 255, 255, 0.2);
 border-radius: 5px;
}
