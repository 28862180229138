@media only screen and (max-width: 767px) {
 .logoContainer {
  width: 100%;
  height: 20vh;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .logoPart {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
 }

 .logoPart svg {
  height: 80px;
  width: 15%;
 }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
 .logoContainer {
  width: 100%;
  height: 30vh;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .logoPart {
  width: 80%;
  height: 100%;
  display: flex;
  /* background-color: red; */
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
 }

 .logoPart svg {
  height: 80px;
  width: 15%;
 }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
 .logoContainer {
  width: 100%;
  height: 30vh;
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .logoPart {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
 }

 .logoPart svg {
  /* height: 80px; */
  width: 15%;
 }
}

@media only screen and (min-width: 1440px) {
 .logoContainer {
  width: 100%;
  height: 30vh;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .logoPart {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
 }
}
